
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { User } from '@/api-domain/user';
import { answerService } from '@/services/answer.service';
import EzAnswer from '@/components/teams/EzAnswer.vue';
import { UserAnswer } from '@/api-domain/userAnswer';
import { userService } from '@/services/user.service';
import { cardService } from '@/services/card.service';
import { packService } from '@/services/pack.service';
import { Pack } from '@/api-domain/pack';
import { Card } from '@/api-domain/card';

type DeckMapItem = [string, string];
type DeckMap = Map<string, string>
type CardMapItem = [string, string];
type CardMap = Map<string, string>

function createDeckMap(allDecks: Pack[]): DeckMap {
  const mapData = allDecks.map((deck) => [deck.id || '', deck.name]) as DeckMapItem[];
  return new Map<string, string>(mapData);
}

function createCardMap(allCards: Card[], deckMap: DeckMap): CardMap {
  const mapData = allCards.map((card) => [card.id || '', deckMap.get(card.packId)]) as CardMapItem[];
  return new Map<string, string>(mapData);
}

@Options({
  components: {
    EzAnswer,
  },
})
export default class Answers extends Vue {
  @Prop({ isRequired: true }) userId!: string;

  cardMap: CardMap = new Map<string, string>();

  user?: User;

  userAnswers: UserAnswer[] = [];

  fetchError = false;

  isFetched = false;

  async fetchAnswers(): Promise<void> {
    this.user = await userService.getOne(this.userId);
    const allCards = await cardService.getAll();
    const allDecks = await packService.getAll();
    const deckMap = createDeckMap(allDecks);
    this.cardMap = createCardMap(allCards, deckMap);

    try {
      this.userAnswers = await answerService.getAll(this.userId);
      this.isFetched = true;
    } catch {
      this.fetchError = true;
      // TODO: how to handle? polling in every 10 seconds?
      // try again button?
    }
  }

  async created(): Promise<void> {
    await this.fetchAnswers();
  }

  formatDate(date: string | null): string {
    if (date === null) {
      return '';
    }
    return new Date(date).toLocaleDateString();
  }

  formatTime(date: string | null): string {
    if (date === null) {
      return '';
    }
    return new Date(date).toLocaleTimeString();
  }
}
