import { UserAnswer } from '@/api-domain/userAnswer';
import authHeader from '@/helpers/auth-header';
import http from '@/http-common';

class AnswerService {
  async getAll(userId: string): Promise<UserAnswer[]> {
    const response = await http.get(`/students/${userId}/answers`, { headers: authHeader() });
    return response.data;
  }
}

export const answerService = new AnswerService();
