import authHeader from '@/helpers/auth-header';
import authenticationService from './authentication.service';
import http from '@/http-common';
import { User } from '@/api-domain/user';

class UserService {
  async getAll(): Promise<User[]> {
    const response = await http.get('/students', { headers: authHeader() });
    return response.data;
  }

  async getOne(userId: string): Promise<User> {
    const response = await http.get(`/students/${userId}`, { headers: authHeader() });
    return response.data;
  }
}

export const userService = new UserService();
