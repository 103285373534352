<template>
  <div class="card is-clickable">
    <div class="card-content">
      <div class="content">
        <h4>{{ userAnswer.answer }}</h4>
        <p>{{ userAnswer.timestamp }}</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { UserAnswer } from '@/api-domain/userAnswer';

@Options({
  components: {
  },
})
export default class EzAnswer extends Vue {
  @Prop({ required: true }) userAnswer!: UserAnswer;

  formatDate(date: Date | null): string {
    if (date === null) {
      return '';
    }
    return date.toDateString();
  }
}
</script>
